<script>
import api from "@/api";

export default {
  name: "EquipmentList",
  data: function () {
    return {
      equipment: [],
      edit: null,
      added: {
        name: "",
        notes: "",
      },
    };
  },
  methods: {
    addItem() {
      var url = "/equipment/";
      console.log("Added");
      var data = {
        name: this.added.name,
        notes: this.added.notes,
        slug: this.space.slug,
      };
      api.Util.axios.post(url, data).then(() => {
        this.added = {
          name: "",
          notes: "",
          slug: this.space.slug,
        };
        this.get();
      });
    },
    save() {
      const url = `/equipment/${this.edit.id}/`;
      console.log("Save");
      api.Util.axios
        .patch(url, {
          name: this.edit.name,
          notes: this.edit.notes,
        })
        .then(() => {
          for (var i = 0; i < this.equipment.length; i++) {
            if (this.equipment[i].id === this.edit.id) {
              this.equipment[i] = this.edit;
              break;
            }
          }
          this.edit = null;
        });
    },
    get() {
      api.SpaceLocations.equipment(this.space.id).then((response) => {
        const data = response.data;
        if (data.results) {
          this.equipment = data.results;
        } else {
          this.equipment = data;
        }
      });
    },
    deleteItem(id) {
      if (!confirm("Are you sure you want to delete this?")) {
        return;
      }
      const url = `/equipment/${id}/`;
      api.Util.axios.delete(url).then(() => {
        var e = this.equipment;
        for (var i = 0; i < e.length; i++) {
          if (e[i].id === id) {
            e.splice(i, 1);
            break;
          }
        }
        this.equipment = e;
      });
    },
  },
  computed: {
    space() {
      return this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  created() {
    this.get();
  },
};
</script>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-md-8 text-center" id="EquipmentList">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Notes</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in equipment" :key="item.id">
                <template v-if="edit != null && edit.id == item.id">
                  <td>
                    <input
                      class="form-control"
                      name="name"
                      v-model="edit.name"
                    />
                  </td>
                  <td>
                    <textarea
                      class="form-control"
                      name="notes"
                      v-model="edit.notes"
                    />
                  </td>
                  <td>
                    <button class="btn btn-primary" @click="save">Save</button>
                  </td>
                </template>
                <template v-else>
                  <td>{{ item.name }}</td>
                  <td>{{ item.notes }}</td>
                  <td>
                    <button class="btn btn-success" @click="edit = item">
                      <i class="fa fa-pencil"></i>
                      Edit
                    </button>
                    <button class="btn btn-danger" @click="deleteItem(item.id)">
                      <i class="fa fa-trash"></i>
                      Delete
                    </button>
                  </td>
                </template>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    v-model="added.name"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="added.notes"
                    class="form-control"
                  />
                </td>
                <td>
                  <button class="btn btn-primary" @click="addItem">Add</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#EquipmentList {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  background: white;
  box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
  border: 1px solid rgb(241, 241, 241);
}
</style>
